export default `
  query getProductInfoForCombiningInCart(
    $filter: ProductAttributeFilterInput
  ) {
    products(filter: $filter) {
      items {
        id
        sku
        name
        no_bzt_export
        zuora_completed
        fulfillment_enabled
        ... on ConfigurableProduct {
            variants{
              product{
                    id
                    sku
                    name
                    no_bzt_export
                    zuora_completed
                    fulfillment_enabled
              }
          }
        }
      }
    }
  }
`;
