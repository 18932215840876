import { computed, reactive } from '@nuxtjs/composition-api';
import { StateAutocompleteInterface, UseAutocompleteInterface } from '~/composables/useAutocomplete/useAutocomplete';
import axios from 'axios';

const state = reactive<StateAutocompleteInterface>({
    suggestedTerms: []
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseAutocompleteInterface} for a list of methods and values available in this composable.
 */
export function useAutocomplete(): UseAutocompleteInterface {

  const loadSuggestedTermsResults = async (term: String) => {
    const decoder = new TextDecoder('iso-8859-1');
    const baseUrl = `https://r24api.ilsole24ore.com/autocomp?profilo=sh24_titoli&output=json&q=${term}`; // useConfig.
    const apiResult = await axios.post(baseUrl, null, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
        responseType: 'arraybuffer',
        withCredentials: false
    })

    try {
      const text = decoder.decode(apiResult.data);
      const data = JSON.parse(text);
      const uniqueValues = [...new Set(data.map(item => ({
        suggestedTerm: item.extra_info.split('||')[2].trim(),
        queriedTerm: item.query.replace(/§/g, "")
      })))];
      state.suggestedTerms = uniqueValues as any;
    } catch (e) {
      console.error("### - Error during autocomplete fetch - ###", e)
    }

  }

  const resetSuggestedTerms = () => state.suggestedTerms = [];

  return {
    // @ts-ignore
    suggestedTerms: computed(() => state.suggestedTerms ),
    resetSuggestedTerms,
    loadSuggestedTermsResults,
  };
}

export * from './useAutocomplete';
export default useAutocomplete;
