






















import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api'
import { SfIcon } from '@storefront-ui/vue'
import { useUiState } from '~/composables'
import LoginModal from '~/modules/customer/components/LoginModal/LoginModal.vue'

export default defineComponent({
  components: {
    SfIcon,
    LoginModal
  },
  setup() {
    const { toggleLoginModal, isLoginModalOpen } = useUiState()
    const shortname = ref<string | null>(null)

    function getCookie(name: string): string | null {
      if (process.client) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) {
          return parts.pop()?.split(';').shift() || null
        }
      }
      return null
    }

    onMounted(() => {
      const soleUserCookie = getCookie('SoleUser')
      if (soleUserCookie) {
        const params = new URLSearchParams("?" + soleUserCookie)
        shortname.value = params.get("shortname")
      }
    })

    function handleToggleLoginModal() {
      if (shortname.value) {
        window.location.href = "https://areautente.ilsole24ore.com/#profile"
      } else {
        toggleLoginModal()
      }
    }

    return {
      toggleLoginModal,
      isLoginModalOpen,
      shortname,
      handleToggleLoginModal
    }
  }
})
