






import { defineComponent } from "@nuxtjs/composition-api"
import { addBasePath } from '~/helpers/addBasePath';
import {useLoader} from '~/composables/useLoader'
import Loader from '~/components/GlobalComponents/Loader.vue'
import { useConfig } from "~/composables";
import { SOLEPRO_STORE_CODE } from "~/utils/constants/store";
import Spinner from '~/components/solepro/Spinner.vue';
export default defineComponent({
  components:{
    Loader,
    Spinner
  },
  props: {
    rootClass: {
      type: String,
      default: "bg-white text-black",
    },
    loaderColor:{
      type: String,
      default: 'black'
    },
    forceLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(){
    const {isLoading} = useLoader()
    const { config } = useConfig()
    return {
        addBasePath,
        isLoading,
        isSolePro: config.value.store_code == SOLEPRO_STORE_CODE
    }
  }
});
