

















import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute, defineComponent, onUpdated, ref,
} from '@nuxtjs/composition-api';
import { useUiHelpers } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import PageLoading from '~/components/GlobalComponents/PageLoading.vue';
import { PAYWALL_LINKS, PAYWALL_SCRIPTS, PAYWALL_TITLE } from '~/utils/constants/nuxt/metaInfos';
import ClientOnly from 'vue-client-only'

export default defineComponent({
  name: 'DefaultLayout',
  head() {
    return {
      htmlAttrs: {
        lang: 'it'
      },
      title: PAYWALL_TITLE,
      script: PAYWALL_SCRIPTS,
      link: PAYWALL_LINKS
    }
  },
  components: {
    ClientOnly,
    PageLoading,
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },
  setup() {
    const route = useRoute();
    const { getBodyBgColor } = useUiHelpers();
    const bodyColor = ref<String>(getBodyBgColor());

    onUpdated(() => {
      bodyColor.value = getBodyBgColor();
    });

    return {
      route,
      bodyColor,
    };
  },
});
