import { computed, reactive } from '@nuxtjs/composition-api';
import useLoader from '../useLoader';
import { StateHomeInterface, UseHomeInterface } from '~/composables/useHome/useHome';

const state = reactive<StateHomeInterface>({
  heroRendered: false,
});

/**
 * Global store for managing UI state.
 * See the {@link StateHomeInterface} for a list of methods and values available in this composable.
 */
export function useHome(): UseHomeInterface {

  const { setIsLoading } = useLoader();

  const homeLoading = () => {
    setIsLoading(true);
  }

  const setHeroRendered = (val: Boolean) => {
    state.heroRendered = val;
    setIsLoading(false);
  }

  return {
    // @ts-ignore
    heroRendered: computed(() => state.heroRendered ),
    setHeroRendered,
    homeLoading
  };
}

export * from './useHome';
export default useHome;
